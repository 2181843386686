<script setup lang="ts">
const showPreloader = ref(true)

onMounted(async () => {
  await nextTick()
  showPreloader.value = false
})
</script>

<template>
  <div v-if="showPreloader" class="preloader">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="180"
      height="60"
      class="preloader-pulse"
      viewBox="0 0 105 40"
      fill="none"
    >
      <g clip-path="url(#clip0_4768_165038)">
        <circle cx="15.0785" cy="20.4145" r="6.5004" fill="url(#paint0_linear_4768_165038)" />
        <path
          d="M16.8633 9.84375C21.2567 9.84375 24.8184 13.4054 24.8184 17.7988V26.914C20.4249 26.914 16.8633 23.3524 16.8633 18.9589V9.84375Z"
          fill="#FF004B"
        />
        <path
          d="M88.0859 27.2672C85.7264 27.2672 84.1211 25.7696 84.1211 23.7226V23.7118C84.1211 21.5032 85.8234 20.2642 88.8508 20.0595L91.5228 19.8871V19.3053C91.5228 18.4541 90.9733 17.9262 89.9067 17.9262C88.8832 17.9262 88.2798 18.4003 88.1505 19.0036L88.129 19.1114H84.6921L84.7029 18.9713C84.886 16.6872 86.8361 15.125 90.1006 15.125C93.2574 15.125 95.3368 16.7195 95.3368 19.1006V27.1056H91.5228V25.4464H91.4474C90.7794 26.5992 89.6158 27.2672 88.0859 27.2672ZM87.8704 23.4748C87.8704 24.2074 88.4845 24.6276 89.4219 24.6276C90.6501 24.6276 91.5228 23.895 91.5228 22.9038V22.1173L89.465 22.2573C88.3983 22.322 87.8704 22.7637 87.8704 23.464V23.4748Z"
          fill="#222020"
        />
        <path d="M79.7148 27.1054V11.5586H83.5396V27.1054H79.7148Z" fill="#222020" />
        <path
          d="M73.0558 27.3534C69.3388 27.3534 67.0547 25.1124 67.0547 21.2446V21.223C67.0547 17.4198 69.4142 15.125 73.0558 15.125C76.7081 15.125 79.0568 17.3983 79.0568 21.223V21.2446C79.0568 25.1232 76.7512 27.3534 73.0558 27.3534ZM73.0665 24.4552C74.3702 24.4552 75.189 23.3024 75.189 21.2446V21.223C75.189 19.1975 74.3486 18.0232 73.0558 18.0232C71.7629 18.0232 70.9333 19.1975 70.9333 21.223V21.2446C70.9333 23.3132 71.7413 24.4552 73.0665 24.4552Z"
          fill="#222020"
        />
        <path
          d="M61.1377 27.3534C57.4314 27.3534 55.1797 25.037 55.1797 21.2446V21.2338C55.1797 17.4629 57.4422 15.125 61.0084 15.125C64.553 15.125 66.8048 17.4414 66.8048 20.9968V22.1173H58.9182C58.9613 23.701 59.8233 24.6384 61.2131 24.6384C62.4413 24.6384 63.1093 23.9381 63.2817 23.4532L63.2925 23.3994H66.7401L66.7078 23.5287C66.3523 25.1555 64.8762 27.3534 61.1377 27.3534ZM61.0946 17.84C59.9741 17.84 59.166 18.5727 58.9721 19.8655H63.2063C63.0123 18.5511 62.2151 17.84 61.0946 17.84Z"
          fill="#222020"
        />
        <path
          d="M47.617 27.1039L43.6953 15.3711H47.8002L49.8472 23.904H49.9226L51.9266 15.3711H55.9237L52.0343 27.1039H47.617Z"
          fill="#222020"
        />
        <path
          d="M30.0859 27.1054L35.3221 11.5586H40.1596L45.3957 27.1054H41.2477L40.2781 23.69H35.2036L34.2339 27.1054H30.0859ZM37.7031 14.9416L36.0224 20.8457H39.4593L37.7785 14.9416H37.7031Z"
          fill="#222020"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4768_165038"
          x1="15.0785"
          y1="13.9141"
          x2="15.0785"
          y2="26.9149"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFE1EF" />
          <stop offset="1" stop-color="#99878F" stop-opacity="0" />
        </linearGradient>
        <clipPath id="clip0_4768_165038">
          <rect width="105" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<style scoped>
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.preloader {
  position: static;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preloader > svg {
  animation: pulse 2s infinite;
  z-index: -1;
}
</style>
